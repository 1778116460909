export const authentication = async (setLoader, setUsername, setPlan, navigate) => {
  setLoader(true);
  const res = await fetch("account/index", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  });
  const data = await res.json();
  if (data.error || !data) {
    navigate("/login");
  } else {
    setUsername(data.userName);
    setPlan(data.plan);
  }
  setLoader(false);
};
