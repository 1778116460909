export const googleSuccess = (response, navigate, toast, jwtDecode) => {
  const decoded = jwtDecode(response.credential);
  const user = {
    name: decoded.name,
    email: decoded.email,
    picture: decoded.picture,
  };

  fetch("account/googleLogin", {
    method: "POST",
    body: JSON.stringify({
      name: user.name,
      email: user.email,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.message) {
        navigate("/dashboard");
      } else if (data.error) {
        toast(data.error, {
          type: "error",
          autoClose: 6000,
        });
      } else {
        toast("Unexpected Error", {
          type: "error",
          autoClose: 6000,
        });
      }
    })
    .catch((error) => {
      toast(String(error), {
        type: "error",
        autoClose: 6000,
      });
    });
};