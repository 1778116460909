import { React, useEffect, useState } from "react";
import Header from "../Components/Header";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../assets/css/Login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderLogo from "../Components/HeaderLogo";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Cookies from "universal-cookie";
import { userLogin } from "../apis/onboarding/loginUser";
import { googleSuccess } from "../apis/onboarding/googleSuccess";
import { githubLogin } from "../apis/onboarding/githubLogin";

export function Login() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  // const [redirectedToGithub, setRedirectedToGithub] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };
  const [data, setData] = useState({ errorMessage: "", isLoading: false });
  const [user, setUser] = useState(null);
  // const [gitHit, setGitHit] = useState(false);
  const location = useLocation();

  useEffect(() => {
    githubLogin(navigate, toast); // }
  }, [navigate]);

  const handleGithubLogin = () => {
    window.location.href =
      "https://github.com/login/oauth/authorize?scope=user&client_id=Ov23lilF0qPJ7JAM1eAb&redirect_uri=http://192.168.1.76:3000/login";
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (!validateEmail(newEmail)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (newPassword.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
    } else {
      setPasswordError("");
    }
  };

  const handleSuccess = (response) => {
    googleSuccess(response, navigate, toast, jwtDecode);
  };

  const handleFailure = (response) => {
    console.log("Login failed:", response);
  };

  const loginUser = async (e) => {
    toast("500 Website is currently down", {
      type: "error",
      autoClose: 6000,
    });
    userLogin(e, setLoading, email, password, toast, cookies, navigate);
  };

  return (
    <div className="login_page">
      <Header />
      <div className="login-page w-100 d-flex flex-column align-items-center ">
        <HeaderLogo />
        <h5 className="mt-3 mb-2">Log in to PlotAnt</h5>

        <form
          action=""
          method="post"
          className="login-form d-flex flex-column w-25"
        >
          <div className="d-flex flex-column w-100 align-items-center">
            {/* <div className="d-flex flex-row align-items-center justify-content-center py-1 px-2 login-google mt-3 mb-2 "> */}
            {/* <img
                src={require("../assets/imgs/google.png")}
                alt=""
                className=""
              />

              <p className="">Login with Google</p> */}
            <GoogleOAuthProvider clientId="1002472500377-vi31jo7u7go6d0rugck0touirlnif15r.apps.googleusercontent.com">
              <div style={{ maxWidth: "max-content" }} className="my-2">
                <GoogleLogin
                  onSuccess={handleSuccess}
                  onFailure={handleFailure}
                  cookiePolicy={"single_host_origin"}
                />
              </div>
            </GoogleOAuthProvider>
            {/* </div> */}
            <div>
              <a
                onClick={handleGithubLogin}
                className="d-flex flex-row align-items-center github-link justify-content-center py-2 px-2 mb-3 w-100"
                style={{ color: "black" }}
              >
                <img
                  src={require("../assets/imgs/github.png")}
                  alt=""
                  className="mx-1"
                />
                <p className="mx-1">Login with Github</p>
              </a>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center w-100 my-3">
            <div
              className="flex-grow-1 hr-line border-bottom"
              style={{ border: "1px solid lightgray" }}
            ></div>
            <p className="mx-2 mb-0 text-muted">or</p>
            <div
              className="flex-grow-1 hr-line border-bottom"
              style={{ border: "1px solid lightgray" }}
            ></div>
          </div>
          <label
            htmlFor=""
            className="mt-1"
            style={{ color: "gray", fontWeight: "bold" }}
          >
            Email
          </label>
          <input
            type="email"
            name=""
            id="email"
            placeholder=""
            value={email}
            onChange={handleEmailChange}
            className="login-input py-1"
          />
          {emailError && (
            <p style={{ color: "red", fontSize: "12px" }} className="">
              {emailError}
            </p>
          )}
          <label
            htmlFor=""
            className="mt-3"
            style={{ color: "gray", fontWeight: "bold" }}
          >
            Password
          </label>
          <input
            type="password"
            name=""
            id="password"
            placeholder=""
            value={password}
            onChange={handlePasswordChange}
            className="login-input py-1"
          />
          {passwordError && (
            <p style={{ color: "red", fontSize: "12px" }} className="">
              {passwordError}
            </p>
          )}

          {loading ? (
            <button
              type="button"
              className="btn login-button text-white mt-4"
              disabled
            >
              Logging in....
            </button>
          ) : (
            <button
              type="submit"
              onClick={loginUser}
              className="btn login-button text-white mt-4"
            >
              Log in
            </button>
          )}

          {/* <Link style={{fontSize:"0.8rem"}} className="d-flex flex-row justify-content-center align-items-center btn bg-dark text-white"><img src={GoogleLogo} alt="google logo" className="mx-2"/>Sign in with Google</Link> */}
          {/* <GoogleOAuthProvider clientId="997143974374-g2ifonuqctckji0udpvn30pqouti75eg.apps.googleusercontent.com">
                            <Google />
                        </GoogleOAuthProvider> */}
          <p
            style={{ fontSize: "0.9rem", color: "gray" }}
            className="text-center mt-4 mb-2"
          >
            Don't have an account?{" "}
            <Link
              to="/register"
              className="register-link"
              style={{ color: "var(--primary-color)" }}
            >
              Register for free
            </Link>
          </p>
          <Link
            style={{
              fontSize: "0.9rem",
              color: "#15589c",
              textDecoration: "underline",
            }}
            className="my-2 text-center forget-button"
          >
            Forget Password?
          </Link>
          <p
            className="text-center mt-3"
            style={{ color: "gray", fontSize: "0.75rem" }}
          >
            The site is protected by reCAPTCHA and the Google Privacy Policy and
            Terms of Service apply.
          </p>
        </form>
        {/* {profile ? (
                <div>
                    <img src={profile.picture} alt="user image" />
                    <h3>User Logged in</h3>
                    <p>Name: {profile.name}</p>
                    <p>Email Address: {profile.email}</p>
                    <br />
                    <br />
                    <button onClick={logOut}>Log out</button>
                </div>
            ) : (
                <button onClick={() => login()}>Sign in with Google 🚀 </button>
            )} */}
      </div>
    </div>
  );
}

export default Login;
