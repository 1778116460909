import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/css/Api.css";
import { Header } from "../Components/Header";

export function Api() {
    //   const [loader, setLoader] = useState(true);
    const [apiname, setApiName] = useState("createUser");
    //   useEffect(() => {
    //     const timeout = setTimeout(() => {
    //       setLoader(false);
    //     }, 3000);

    //     return () => clearTimeout(timeout);
    //   }, []);

    //   if (loader) {
    //     return (
    //       <div className="loader d-flex text-center flex-column justify-content-center align-items-center">
    //         <LandingPageLoader />
    //         <h1>PlotAnt</h1>
    //       </div>
    //     );
    //   }

    return (
        <div className="home-page">
            <div className="homebg"></div>
            <Header />
            <div className="row api" style={{ height: '100vh' }}>
                <div className="api-section col col-2 d-flex flex-column px-3">
                    <p style={{ color: 'gray' }} className="mb-3">REST ENDPOINTS</p>
                    <p style={{ color: 'gray', fontSize: '0.9rem' }} className="mb-1">User</p>
                    <p className="api-endpoint my-1" onClick={() => setApiName("createUser")}>Create User <span className="api-method api-method-post">POST</span></p>
                    <p className="api-endpoint mb-1">Get User Information <span className="api-method api-method-get">GET</span></p>
                    <p className="api-endpoint my-1">Delete Account <span className="api-method api-method-delete">DELETE</span></p>
                    <p style={{ color: 'gray', fontSize: '0.9rem' }} className="mb-1 mt-2">Graphs</p>
                    <p style={{ color: 'gray', fontSize: '0.9rem' }} className="mb-1 mt-2">Latex</p>
                    <p className="api-endpoint my-1">Get Latex Code <span className="api-method api-method-post">POST</span></p>

                </div>
                <div className="api-section col col-5 d-flex flex-column px-4">
                    {
                        apiname === "createUser" ?
                            <div className="d-flex flex-column">
                                <h2>Create a User</h2>
                                <p style={{ fontSize: '0.9rem' }} className="mt-2"><span className="api-method api-method-post">POST</span> <Link to="http://192.168.1.73:8800/">http://192.168.1.73:8800/createUser</Link></p>
                                <p className="mt-2">This endpoint creates a new user</p>
                                <p style={{ fontSize: '0.8rem', color: 'gray', fontWeight: 'bold' }} className="mt-3">BODY PARAMS</p>
                                <p style={{ fontSize: '0.8rem', color: 'gray' }} className="mt-1">Query parameters to be provided in the request body as a JSON object</p>
                                <div className="d-flex flex-column mt-2" style={{ background: 'rgb(216 216 216)', border: '1px solid lightgray', borderRadius: '8px' }}>
                                    <div className="d-flex flex-row w-100 justify-content-between px-3 py-2" style={{ borderBottom: '1px solid gray' }}>
                                        <div className="d-flex flex-column">
                                            <p>name <span style={{ color: 'gray', fontSize: '0.7rem' }}>string | null <span style={{ color: 'red' }}>required</span></span></p>
                                            <p style={{ fontSize: '0.8rem' }}>The name of the user</p>
                                        </div>
                                        <input type="text" name="" id="" style={{ border: '1px solid #c3c3c3', background: 'lightgray', borderRadius: '8px' }} className="mx-3 my-2" />
                                    </div>
                                    <div className="d-flex flex-row w-100 justify-content-between px-3 py-2">
                                        <div className="d-flex flex-column">
                                            <p>description <span style={{ color: 'gray', fontSize: '0.7rem' }}>string | null</span></p>
                                            <p style={{ fontSize: '0.8rem' }}>A description of the user</p>
                                        </div>
                                        <input type="text" name="" id="" style={{ border: '1px solid #c3c3c3', background: 'lightgray', borderRadius: '8px' }} className="mx-3 my-2" />
                                    </div>
                                </div>
                                <hr className="mt-5" style={{ color: 'gray' }} />
                                <p style={{ fontSize: '0.8rem', color: 'gray', fontWeight: 'bold' }} className="mt-3">RESPONSE</p>
                                <div className="d-flex flex-column mt-2" style={{ background: 'rgb(216 216 216)', border: '1px solid lightgray', borderRadius: '8px' }}>
                                    <div className="d-flex flex-row w-100 justify-content-between align-items-center px-3 py-2" style={{ borderBottom: '1px solid #c3c3c3' }}>
                                        <div className="d-flex flex-column">
                                            <p style={{fontSize:'0.9rem'}}><span><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_i_670_5542)">
                                                    <circle cx="6" cy="6" r="6" fill="#61F25E" />
                                                </g>
                                                <circle cx="6" cy="6" r="5.5" stroke="black" stroke-opacity="0.25" />
                                                <defs>
                                                    <filter id="filter0_i_670_5542" x="0" y="0" width="12" height="13" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dy="1" />
                                                        <feGaussianBlur stdDeviation="4.4" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_670_5542" />
                                                    </filter>
                                                </defs>
                                            </svg>
                                            </span> 200</p>
                                            <p style={{ fontSize: '0.8rem' }}>Responses for POST /createUser</p>
                                        </div>
                                        <svg width="5" height="15" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.3156 12.7294L12.7294 11.3156L1.41375 0L0 1.41375L9.90188 11.3156L0.0468761 21.1706L1.46063 22.5844L7.11 16.935L11.3156 12.7294Z" fill="gray" />
                                        </svg>

                                    </div>
                                    <div className="d-flex flex-row w-100 justify-content-between align-items-center px-3 py-2">
                                        <div className="d-flex flex-column">
                                            <p style={{fontSize:'0.9rem'}}><span><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_i_670_5543)">
                                                    <circle cx="6" cy="6" r="6" fill="#F25E67" />
                                                </g>
                                                <circle cx="6" cy="6" r="5.5" stroke="black" stroke-opacity="0.25" />
                                                <defs>
                                                    <filter id="filter0_i_670_5543" x="0" y="0" width="12" height="13" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dy="1" />
                                                        <feGaussianBlur stdDeviation="4.4" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_670_5543" />
                                                    </filter>
                                                </defs>
                                            </svg>
                                            </span> 500</p>
                                            <p style={{ fontSize: '0.8rem' }}>Responses for POST /createUser</p>
                                        </div>
                                        <svg width="5" height="15" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.3156 12.7294L12.7294 11.3156L1.41375 0L0 1.41375L9.90188 11.3156L0.0468761 21.1706L1.46063 22.5844L7.11 16.935L11.3156 12.7294Z" fill="gray" />
                                        </svg>

                                    </div>
                                </div>
                            </div>

                            :
                            <p>Saim</p>
                    }
                </div>
                <div className="api-section col col-5 d-flex flex-column px-4" style={{ borderRight: 'none' }}>
                {
                        apiname === "createUser" ?
                            <div className="d-flex flex-column">
                                <p style={{ fontSize: '0.8rem', color: 'gray', fontWeight: 'bold' }} className="mt-3">LANGUAGE</p>
                                <div className="d-flex flex-row mt-2" style={{width:'730px', overflowX:'auto'}}>
                                    <div className="d-flex flex-column w-100 align-items-center px-3 py-2">
                                        <img src={require('../assets/imgs/languages/nodejs.png')} alt="" style={{width:'20px'}}/>
                                        <p style={{fontSize:'0.9rem'}}>Node</p>
                                    </div>
                                    <div className="d-flex flex-column w-100 align-items-center px-3 py-2">
                                        <img src={require('../assets/imgs/languages/nodejs.png')} alt="" style={{width:'20px'}}/>
                                        <p style={{fontSize:'0.9rem'}}>Node</p>
                                    </div>
                                    <div className="d-flex flex-column w-100 align-items-center px-3 py-2">
                                        <img src={require('../assets/imgs/languages/nodejs.png')} alt="" style={{width:'20px'}}/>
                                        <p style={{fontSize:'0.9rem'}}>Node</p>
                                    </div>
                                    <div className="d-flex flex-column w-100 align-items-center px-3 py-2">
                                        <img src={require('../assets/imgs/languages/nodejs.png')} alt="" style={{width:'20px'}}/>
                                        <p style={{fontSize:'0.9rem'}}>Node</p>
                                    </div>
                                    <div className="d-flex flex-column w-100 align-items-center px-3 py-2">
                                        <img src={require('../assets/imgs/languages/nodejs.png')} alt="" style={{width:'20px'}}/>
                                        <p style={{fontSize:'0.9rem'}}>Node</p>
                                    </div>
                                    <div className="d-flex flex-column w-100 align-items-center px-3 py-2">
                                        <img src={require('../assets/imgs/languages/nodejs.png')} alt="" style={{width:'20px'}}/>
                                        <p style={{fontSize:'0.9rem'}}>Node</p>
                                    </div>
                                    <div className="d-flex flex-column w-100 align-items-center px-3 py-2">
                                        <img src={require('../assets/imgs/languages/nodejs.png')} alt="" style={{width:'20px'}}/>
                                        <p style={{fontSize:'0.9rem'}}>Node</p>
                                    </div>
                                    <div className="d-flex flex-column w-100 align-items-center px-3 py-2">
                                        <img src={require('../assets/imgs/languages/nodejs.png')} alt="" style={{width:'20px'}}/>
                                        <p style={{fontSize:'0.9rem'}}>Node</p>
                                    </div>
                                    <div className="d-flex flex-column w-100 align-items-center px-3 py-2">
                                        <img src={require('../assets/imgs/languages/nodejs.png')} alt="" style={{width:'20px'}}/>
                                        <p style={{fontSize:'0.9rem'}}>Node</p>
                                    </div>
                                    <div className="d-flex flex-column w-100 align-items-center px-3 py-2">
                                        <img src={require('../assets/imgs/languages/nodejs.png')} alt="" style={{width:'20px'}}/>
                                        <p style={{fontSize:'0.9rem'}}>Node</p>
                                    </div>
                                    <div className="d-flex flex-column w-100 align-items-center px-3 py-2">
                                        <img src={require('../assets/imgs/languages/nodejs.png')} alt="" style={{width:'20px'}}/>
                                        <p style={{fontSize:'0.9rem'}}>Node</p>
                                    </div>
                                </div>
                                <hr className="mt-5" style={{ color: 'gray' }} />
                                <p style={{ fontSize: '0.8rem', color: 'gray', fontWeight: 'bold' }} className="mt-3">RESPONSE</p>
                                <div className="d-flex flex-column mt-2" style={{ background: 'rgb(216 216 216)', border: '1px solid lightgray', borderRadius: '8px' }}>
                                    <div className="d-flex flex-row w-100 justify-content-between align-items-center px-3 py-2" style={{ borderBottom: '1px solid #c3c3c3' }}>
                                        <div className="d-flex flex-column">
                                            <p style={{fontSize:'0.9rem'}}><span><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_i_670_5542)">
                                                    <circle cx="6" cy="6" r="6" fill="#61F25E" />
                                                </g>
                                                <circle cx="6" cy="6" r="5.5" stroke="black" stroke-opacity="0.25" />
                                                <defs>
                                                    <filter id="filter0_i_670_5542" x="0" y="0" width="12" height="13" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dy="1" />
                                                        <feGaussianBlur stdDeviation="4.4" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_670_5542" />
                                                    </filter>
                                                </defs>
                                            </svg>
                                            </span> 200</p>
                                            <p style={{ fontSize: '0.8rem' }}>Responses for POST /createUser</p>
                                        </div>
                                        <svg width="5" height="15" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.3156 12.7294L12.7294 11.3156L1.41375 0L0 1.41375L9.90188 11.3156L0.0468761 21.1706L1.46063 22.5844L7.11 16.935L11.3156 12.7294Z" fill="gray" />
                                        </svg>

                                    </div>
                                    <div className="d-flex flex-row w-100 justify-content-between align-items-center px-3 py-2">
                                        <div className="d-flex flex-column">
                                            <p style={{fontSize:'0.9rem'}}><span><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_i_670_5543)">
                                                    <circle cx="6" cy="6" r="6" fill="#F25E67" />
                                                </g>
                                                <circle cx="6" cy="6" r="5.5" stroke="black" stroke-opacity="0.25" />
                                                <defs>
                                                    <filter id="filter0_i_670_5543" x="0" y="0" width="12" height="13" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dy="1" />
                                                        <feGaussianBlur stdDeviation="4.4" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_670_5543" />
                                                    </filter>
                                                </defs>
                                            </svg>
                                            </span> 500</p>
                                            <p style={{ fontSize: '0.8rem' }}>Responses for POST /createUser</p>
                                        </div>
                                        <svg width="5" height="15" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.3156 12.7294L12.7294 11.3156L1.41375 0L0 1.41375L9.90188 11.3156L0.0468761 21.1706L1.46063 22.5844L7.11 16.935L11.3156 12.7294Z" fill="gray" />
                                        </svg>

                                    </div>
                                </div>
                            </div>

                            :
                            <p>Saim</p>
                    }
                </div>
            </div>
        </div>
    );
}

export default Api;
