export const getProjectCount = async (setMyProjectsCount, setLastDate, setSharedProjectsCount, setLastSharedDate) => {
  const res = await fetch(
    "analysis/projectsCount",
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    }
  );

  const data = await res.json();
  console.log(data);

  setMyProjectsCount(data.projectsCount);
  console.log(data.latestProject);
  setLastDate(data.latestProject);
  setSharedProjectsCount(data.sharedCount);
  setLastSharedDate(data.latestSharedProject);
  // if (data.message) {
  //   navigate("/");
  // }
};

// Talal Khan
// export const getProjectCount = async (setMyProjectsCount, setLastDate, setSharedProjectsCount, setLastSharedDate) => {
//   try {
//     const res = await fetch("http://64.227.180.23:8800/analysis/projectsCount", {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//       credentials: "include",
//     });

//     if (!res.ok) {
//       throw new Error(`HTTP error! status: ${res.status}`);
//     }

//     const data = await res.json();
//     console.log(data);

//     setMyProjectsCount(data.projectsCount);
//     setLastDate(data.latestProject);
//     setSharedProjectsCount(data.sharedCount);
//     setLastSharedDate(data.latestSharedProject);
//   } catch (error) {
//     console.error("Failed to fetch project count:", error);
//   }
// };
// Talal Khan