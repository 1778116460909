import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
export function Logout() {
  const cookies = new Cookies();
  const navigate = useNavigate();

  useEffect(() => {
    loggingOut();
  }, []);

  const loggingOut = async () => {
    cookies.remove("token", { path: "/" });
    navigate("/login");
  };

  return (
    <div style={{ background: "white", height: "100vh" }}>
      {/* <p>Logging out...</p> */}
    </div>
  );
}
