import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "../../App.css";

const SelectCard = ({ show, onClose, onSubmit }) => {
  const [cards, setCards] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null);

  useEffect(() => {
    getPaymentCards();
  }, []);

  const getPaymentCards = async () => {
    const res = await fetch(
      "account/getCards",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        credentials: "include",
      }
    );

    const data = await res.json();
    if (data.cards) {
      setCards(data.cards);
    } else if (data.error) {
      toast(data.error, {
        type: "error",
        autoClose: 6000,
      });
    }
  };

  const handleSelectCard = (id) => {
    setSelectedCardId(id);
  };

  const handleSubmit = () => {
    if (selectedCardId) {
      onSubmit({ cardId: selectedCardId });
      onClose();
    } else {
      toast("Please select a card", {
        type: "warning",
        autoClose: 6000,
      });
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Payment Accounts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {cards.map((card) => {
          const lastFourDigits = card.card_number.slice(-4);
          const cardLogoSrc = card.card_number.startsWith("5")
            ? "https://img.icons8.com/color/48/000000/mastercard-logo.png"
            : "https://img.icons8.com/color/48/000000/visa.png";

          return (
            <div className="row row-1 mb-3" key={card.id}>
              <div className="col-2">
                <img className="img-fluid" src={cardLogoSrc} alt="Card logo" />
              </div>
              <div className="col-7">
                <input
                  className="payment-input"
                  type="text"
                  placeholder={`**** **** **** ${lastFourDigits}`}
                  disabled
                />
              </div>
              <div className="col-3 d-flex justify-content-center align-items-center">
                <Button
                  variant="primary"
                  onClick={() => handleSelectCard(card.id)}
                  active={selectedCardId === card.id}
                  style={{ background: "var(--primary-color)" }}
                >
                  {selectedCardId === card.id ? "Selected" : "Select"}
                </Button>
              </div>
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary mx-2" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          style={{ background: "var(--primary-color)" }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectCard;
