import React, { createContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [notificationsToast, setNotificationsToast] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState("");
  const [projectDetails, setProjectDetails] = useState([]);

  const getProjectDetails = async () => {
    const res = await fetch("http://64.227.180.23:8800/analysis/projectsList", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const data = await res.json();
    console.log(data);
    setProjectDetails(data.projects);
  };
  const updateNotifications = async () => {
    try {
      const getNotifications = await fetch("http://64.227.180.23:8800/analysis/getNotifications", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        credentials:"include"
      });

      const notificationsData = await getNotifications.json();
      console.log(notificationsData);
      // if (notificationsData) {
      //   setBellStatus(1);
      // }
      setNotifications(notificationsData.bell);
      setNotificationsToast(notificationsData.toaster);
      console.log(notificationsData.unread_count);
      setNotificationsCount(notificationsData.unread_count);
      console.log(notificationsData.toaster);
      notificationsData.toaster.forEach((notification) => {
        console.log(notification);
        toast(notification.message, {
          type: "info",
          autoClose: 6000,
        });
        // console.log(bellStatus);
      });
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        notificationsToast,
        updateNotifications,
        notificationsCount,
        projectDetails,
        getProjectDetails,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
