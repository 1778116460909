export const projectArchive = async (projectId, toast, getProjectDetails) => {
  const res = await fetch(
    "analysis/archiveProject",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        projectId: projectId,
      }),
      credentials: "include",
    }
  );

  const data = await res.json();
  console.log(data);
  if (data.message) {
    toast(data.message, {
      type: "success",
      autoClose: 6000,
    });
  } else if (data.error) {
    toast(data.error, {
      type: "error",
      autoClose: 6000,
    });
  }
  getProjectDetails();

}