export const userLogin = async (e, setLoading, email, password, toast, cookies, navigate) => {
  e.preventDefault();
  setLoading(true);

  const res = await fetch("account/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  });

  const data = await res.json();
  if (data.error || !data) {
    toast(data.error, {
      type: "error",
      autoClose: 6000,
    });
  } else {
    cookies.set("token", data.token, {
      path: "/",
    });
    navigate("/dashboard");
  }
  setLoading(false);
};