export const githubLogin = (navigate, toast) =>{
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");

    if (code) {
      fetch("account/github/callback", {
        method: "POST",
        body: JSON.stringify({ code }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            navigate("/dashboard");
          } else if (data.error) {
            toast(data.error, {
              type: "error",
              autoClose: 6000,
            });
          } else {
            toast("Unexpected Error", {
              type: "error",
              autoClose: 6000,
            });
          }
        })
        .catch((error) => {
          toast(String(error), {
            type: "error",
            autoClose: 6000,
          });
        });
    }
}