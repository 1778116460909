import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../assets/css/searchbar.css";

const SearchBar = ({ projectDetails, searchQuery }) => {
  const [empty, setEmpty] = useState([]);
  const filteredProjects = searchQuery
    ? projectDetails.filter((project) =>
        project.project__name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : empty;

  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  return (
    searchQuery && (
      <div
        style={{
          width: "100%",
          maxHeight: "200px",
          overflowY: "auto",
          zIndex: 100000,
          position: "absolute",
          backgroundColor: "white",
          border: "1px solid #ddd",
          borderRadius: "4px",
          marginTop: "5px",
        }}
        className="searchbar-list"
      >
        {filteredProjects.length === 0 ? (
          <p style={{ padding: "10px", margin: 0 }}>No projects found</p>
        ) : (
          filteredProjects.map((project) => (
            <Link
              key={project.project__id}
              to={`/project/${encodeURIComponent(project.project__id)}`}
              className="d-block"
              style={{
                padding: "10px",
                textDecoration: "none",
                borderBottom: "1px solid #ddd",
                color: "black",
              }}
            >
              <div className="d-flex flex-row align-items-center justify-content-between w-100">
                <p style={{ fontSize: "0.9rem", margin: 0 }}>
                  {project.project__name}
                  <span style={{ fontStyle: "italic", fontSize: "0.85rem" }}>
                    {project.role === "owner"
                      ? " (You)"
                      : ` (${project.project_username})`}
                  </span>
                </p>
                <p style={{ fontSize: "0.85rem", margin: 0 }}>
                  {project.role === "owner"
                    ? "Read & Write"
                    : project.role === "read"
                    ? "Read"
                    : "Read & Write"}
                </p>
              </div>
              <p style={{ color: "gray", fontSize: "0.75rem", margin: 0 }}>
                {formatDate(project.date)}
              </p>
            </Link>
          ))
        )}
      </div>
    )
  );
};

export default SearchBar;
