import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/css/Pricing.css";
import { Header } from "../Components/Header";
import LandingPageLoader from "../Components/Loaders/LandingPageLoader";
import HomeSectionLoader from "../Components/Loaders/HomeSectionLoader";
import UploadAndFetch from "../Components/DataUpload/UploadAndFetch";
import Footer from "../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function Product() {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoader(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  if (loader) {
    return (
      <div className="loader d-flex text-center flex-column justify-content-center align-items-center">
        <LandingPageLoader />
        <h1>PlotAnt</h1>
      </div>
    );
  }

  return (
    <div className="home-page">
      <div className="homebg"></div>
      <Header />
      <div className="d-flex flex-column">
        <div className="d-flex flex-row align-items-center px-4 row my-5">
          <div className="d-flex flex-column col col-6 px-5">
            <div className="d-flex flex-row align-items-center my-1">
              <img src={require("../assets/imgs/product-img.png")} alt="" />
              <p
                style={{
                  color: "var(--primary-color)",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
                className="mx-1"
              >
                Product
              </p>
            </div>
            <h2 className="my-1">
              Create, analyze, and interpret with Plotant
            </h2>
            <p className="my-1">
              Elevate your data analysis with advanced statistics and AI.
              Effortlessly create, analyze, and interpret complex data plots for
              academic and commercial insights.
            </p>
            <Link
              to="/login"
              style={{
                background: "var(--primary-color)",
                color: "white",
                borderRadius: "1rem",
                maxWidth: "max-content",
              }}
              className="px-4 py-1 text-center my-2"
            >
              Try Premium for free
            </Link>
          </div>
          <div className="d-flex flex-row col col-6 px-5">
            <img
              src={require("../assets/imgs/homebg.png")}
              style={{ border: "1px solid lightgray", borderRadius: "0.4rem" }}
              alt=""
              className="w-100"
            />
          </div>
        </div>
        <UploadAndFetch />
      </div>
      <Footer />
    </div>
  );
}

export default Product;
