import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/css/Pricing.css";
import { Header } from "../Components/Header";
import LandingPageLoader from "../Components/Loaders/LandingPageLoader";
import HomeSectionLoader from "../Components/Loaders/HomeSectionLoader";
import UploadAndFetch from "../Components/DataUpload/UploadAndFetch";
import Footer from "../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function Pricing() {
  const [loader, setLoader] = useState(true);
  const [selectedOption, setSelectedOption] = useState("annual");

  const handleToggle = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoader(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  if (loader) {
    return (
      <div className="loader d-flex text-center flex-column justify-content-center align-items-center">
        <LandingPageLoader />
        <h1>PlotAnt</h1>
      </div>
    );
  }

  return (
    <div className="home-page">
      <div className="homebg"></div>
      <Header />
      <section className="mt-3 d-flex flex-column align-items-center">
        <div className="d-flex flex-row align-items-center">
          <button
            className={`annual-button mx-2 px-3 py-2`}
            style={{ fontSize: "0.9rem" }}
            onClick={() => handleToggle("annual")}
          >
            Annual
            <span style={{ fontSize: "0.7rem" }} className="mx-1">
              ( 20% off )
            </span>
          </button>
          <button
            className={`mx-2 annual-button px-3 py-2`}
            style={{ fontSize: "0.9rem" }}
            onClick={() => handleToggle("monthly")}
          >
            Monthly
          </button>
        </div>
        <div className="table-responsive mt-5">
          <table className="table table-bordered">
            <thead className="" style={{ borderTop: "none" }}>
              <tr style={{ borderTop: "none" }}>
                <th
                  scope="col"
                  style={{
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                  className="text-center"
                ></th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ padding: 0, margin: 0, border: 0 }}
                >
                  <div
                    className="d-flex flex-column align-items-center py-4 px-3"
                    style={{
                      borderTop: "1px solid lightgray",
                      borderLeft: "1px solid lightgray",
                      borderRadius: "10px 0 0 0",
                    }}
                  >
                    <p>Free</p>
                    <hr />
                    <p>
                      $0{" "}
                      <span>
                        per {selectedOption === "annual" ? "Year" : "Month"}
                      </span>
                    </p>
                    <ul>
                      <li>One Collaborator per project</li>
                    </ul>
                  </div>
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ padding: 0, margin: 0, border: 0 }}
                >
                  <div
                    className="d-flex flex-column align-items-center py-5"
                    style={{
                      background: "var(--primary-color)",
                      color: "white",
                      borderTop: "1px solid var(--primary-color)",
                      borderRadius: "10px 10px 0 0",
                    }}
                  >
                    <p>Standard</p>
                    <hr />
                    <p>
                      {selectedOption === "annual" ? "$17 " : "$21 "}
                      <span>
                        per {selectedOption === "annual" ? "Year" : "Month"}
                      </span>
                    </p>
                    <ul>
                      <li>5 Collaborators per project</li>
                      <li>All premium features</li>
                    </ul>
                  </div>
                </th>
                <th
                  scope="col"
                  style={{ padding: 0, margin: 0, border: 0 }}
                  className="text-center"
                >
                  <div
                    className="d-flex flex-column align-items-center py-3"
                    style={{
                      borderTop: "1px solid lightgray",
                      borderLeft: 0,
                      borderRight: "1px solid lightgray",
                      borderRadius: "0 10px 0 0",
                    }}
                  >
                    <p>Premium</p>
                    <hr />
                    <p>
                      {selectedOption === "annual" ? "$34 " : "$42 "}
                      <span>
                        per {selectedOption === "annual" ? "Year" : "Month"}
                      </span>
                    </p>
                    <ul>
                      <li>10 Collaborators per project</li>
                      <li>All premium features</li>
                    </ul>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" style={{ background: "white" }}>
                  Price
                </th>
                <td style={{ background: "white" }}>$0</td>
                <td style={{ background: "white" }}>
                  <p>
                    {selectedOption === "annual" ? "$17" : "$21"}
                    <span>
                      /{selectedOption === "annual" ? "Year" : "Month"}
                    </span>
                  </p>
                </td>
                <td style={{ background: "white" }}>
                  <p>
                    {selectedOption === "annual" ? "$34" : "$42"}
                    <span>
                      /{selectedOption === "annual" ? "Year" : "Month"}
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <th scope="row" style={{ background: "white" }}>
                  Project Counts
                </th>
                <td style={{ background: "white" }}>1</td>
                <td style={{ background: "white" }}>5</td>
                <td style={{ background: "white" }}>10</td>
              </tr>
              <tr>
                <th scope="row" style={{ background: "white" }}>
                  Number of people for sharing
                </th>
                <td style={{ background: "white" }}>1</td>
                <td style={{ background: "white" }}>5</td>
                <td style={{ background: "white" }}>10</td>
              </tr>
              <tr>
                <td
                  colSpan="4"
                  className="text-center"
                  style={{
                    background: "lightgray",
                    padding: "10px",
                    fontSize: "0.9rem",
                  }}
                >
                  You and your collaborators will get access to
                </td>
              </tr>
              <tr>
                <th scope="row" style={{ background: "white" }}>
                  Files Per Project
                </th>
                <td style={{ background: "white" }}>2</td>
                <td style={{ background: "white" }}>3</td>
                <td style={{ background: "white" }}>Unlimited</td>
              </tr>
              <tr>
                <th scope="row" style={{ background: "white" }}>
                  File Size
                </th>
                <td style={{ background: "white" }}>5mbs</td>
                <td style={{ background: "white" }}>20mbs</td>
                <td style={{ background: "white" }}>Unlimited mbs</td>
              </tr>
              <tr>
                <th scope="row" style={{ background: "white" }}>
                  Multiple Fields
                </th>
                <td style={{ background: "white" }}>Only on BarChart</td>
                <td style={{ background: "white" }}>Available on all Charts</td>
                <td style={{ background: "white" }}>Available on all Charts</td>
              </tr>
              <tr>
                <th scope="row" style={{ background: "white" }}>
                  Charts Usage
                </th>
                <td style={{ background: "white" }}>Bar, Line and Pie Chart</td>
                <td style={{ background: "white" }}>All Charts available</td>
                <td style={{ background: "white" }}>All Charts available</td>
              </tr>
              <tr>
                <th scope="row" style={{ background: "white" }}>
                  Color Palettes
                </th>
                <td style={{ background: "white" }}>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_669_5536)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M34.2218 32.4531C34.8328 33.0625 34.8328 34.0626 34.2218 34.6719C33.6125 35.2813 32.6219 35.2813 32.0109 34.6719L25.3922 28.0469L18.7265 34.7187C18.1109 35.328 17.1141 35.328 16.4985 34.7187C15.8844 34.0937 15.8844 33.0938 16.4985 32.4844L23.1641 25.8125L16.5453 19.2031C15.9344 18.5938 15.9344 17.5937 16.5453 16.9843C17.1531 16.3749 18.1437 16.3749 18.7547 16.9843L25.3735 23.6093L32.0891 16.8907C32.7047 16.2813 33.7 16.2813 34.3156 16.8907C34.9297 17.5157 34.9297 18.4999 34.3156 19.1249L27.6015 25.8438L34.2218 32.4531ZM25.3828 0.828125C11.575 0.828125 0.382812 12.0156 0.382812 25.8281C0.382812 39.6406 11.575 50.8281 25.3828 50.8281C39.1906 50.8281 50.3828 39.6406 50.3828 25.8281C50.3828 12.0156 39.1906 0.828125 25.3828 0.828125Z"
                        fill="#FF5959"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_669_5536">
                        <rect
                          width="50"
                          height="50"
                          fill="white"
                          transform="translate(0.382812 0.828125)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </td>
                <td style={{ background: "white" }}>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25 0C11.225 0 0 11.225 0 25C0 38.775 11.225 50 25 50C38.775 50 50 38.775 50 25C50 11.225 38.775 0 25 0ZM36.95 19.25L22.775 33.425C22.425 33.775 21.95 33.975 21.45 33.975C20.95 33.975 20.475 33.775 20.125 33.425L13.05 26.35C12.325 25.625 12.325 24.425 13.05 23.7C13.775 22.975 14.975 22.975 15.7 23.7L21.45 29.45L34.3 16.6C35.025 15.875 36.225 15.875 36.95 16.6C37.675 17.325 37.675 18.5 36.95 19.25Z"
                      fill="#61A35C"
                    />
                  </svg>
                </td>
                <td style={{ background: "white" }}>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25 0C11.225 0 0 11.225 0 25C0 38.775 11.225 50 25 50C38.775 50 50 38.775 50 25C50 11.225 38.775 0 25 0ZM36.95 19.25L22.775 33.425C22.425 33.775 21.95 33.975 21.45 33.975C20.95 33.975 20.475 33.775 20.125 33.425L13.05 26.35C12.325 25.625 12.325 24.425 13.05 23.7C13.775 22.975 14.975 22.975 15.7 23.7L21.45 29.45L34.3 16.6C35.025 15.875 36.225 15.875 36.95 16.6C37.675 17.325 37.675 18.5 36.95 19.25Z"
                      fill="#61A35C"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" style={{ background: "white" }}>
                  Theme Customization
                </th>
                <td style={{ background: "white" }}>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_669_5536)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M34.2218 32.4531C34.8328 33.0625 34.8328 34.0626 34.2218 34.6719C33.6125 35.2813 32.6219 35.2813 32.0109 34.6719L25.3922 28.0469L18.7265 34.7187C18.1109 35.328 17.1141 35.328 16.4985 34.7187C15.8844 34.0937 15.8844 33.0938 16.4985 32.4844L23.1641 25.8125L16.5453 19.2031C15.9344 18.5938 15.9344 17.5937 16.5453 16.9843C17.1531 16.3749 18.1437 16.3749 18.7547 16.9843L25.3735 23.6093L32.0891 16.8907C32.7047 16.2813 33.7 16.2813 34.3156 16.8907C34.9297 17.5157 34.9297 18.4999 34.3156 19.1249L27.6015 25.8438L34.2218 32.4531ZM25.3828 0.828125C11.575 0.828125 0.382812 12.0156 0.382812 25.8281C0.382812 39.6406 11.575 50.8281 25.3828 50.8281C39.1906 50.8281 50.3828 39.6406 50.3828 25.8281C50.3828 12.0156 39.1906 0.828125 25.3828 0.828125Z"
                        fill="#FF5959"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_669_5536">
                        <rect
                          width="50"
                          height="50"
                          fill="white"
                          transform="translate(0.382812 0.828125)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </td>
                <td style={{ background: "white" }}>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25 0C11.225 0 0 11.225 0 25C0 38.775 11.225 50 25 50C38.775 50 50 38.775 50 25C50 11.225 38.775 0 25 0ZM36.95 19.25L22.775 33.425C22.425 33.775 21.95 33.975 21.45 33.975C20.95 33.975 20.475 33.775 20.125 33.425L13.05 26.35C12.325 25.625 12.325 24.425 13.05 23.7C13.775 22.975 14.975 22.975 15.7 23.7L21.45 29.45L34.3 16.6C35.025 15.875 36.225 15.875 36.95 16.6C37.675 17.325 37.675 18.5 36.95 19.25Z"
                      fill="#61A35C"
                    />
                  </svg>
                </td>
                <td style={{ background: "white" }}>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25 0C11.225 0 0 11.225 0 25C0 38.775 11.225 50 25 50C38.775 50 50 38.775 50 25C50 11.225 38.775 0 25 0ZM36.95 19.25L22.775 33.425C22.425 33.775 21.95 33.975 21.45 33.975C20.95 33.975 20.475 33.775 20.125 33.425L13.05 26.35C12.325 25.625 12.325 24.425 13.05 23.7C13.775 22.975 14.975 22.975 15.7 23.7L21.45 29.45L34.3 16.6C35.025 15.875 36.225 15.875 36.95 16.6C37.675 17.325 37.675 18.5 36.95 19.25Z"
                      fill="#61A35C"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" style={{ background: "white" }}>
                  No. of Graphs per file
                </th>
                <td style={{ background: "white" }}>3</td>
                <td style={{ background: "white" }}>10</td>
                <td style={{ background: "white" }}>Unlimited</td>
              </tr>
              <tr>
                <th scope="row" style={{ background: "white" }}>
                  Logs Maintenance
                </th>
                <td style={{ background: "white" }}>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_669_5536)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M34.2218 32.4531C34.8328 33.0625 34.8328 34.0626 34.2218 34.6719C33.6125 35.2813 32.6219 35.2813 32.0109 34.6719L25.3922 28.0469L18.7265 34.7187C18.1109 35.328 17.1141 35.328 16.4985 34.7187C15.8844 34.0937 15.8844 33.0938 16.4985 32.4844L23.1641 25.8125L16.5453 19.2031C15.9344 18.5938 15.9344 17.5937 16.5453 16.9843C17.1531 16.3749 18.1437 16.3749 18.7547 16.9843L25.3735 23.6093L32.0891 16.8907C32.7047 16.2813 33.7 16.2813 34.3156 16.8907C34.9297 17.5157 34.9297 18.4999 34.3156 19.1249L27.6015 25.8438L34.2218 32.4531ZM25.3828 0.828125C11.575 0.828125 0.382812 12.0156 0.382812 25.8281C0.382812 39.6406 11.575 50.8281 25.3828 50.8281C39.1906 50.8281 50.3828 39.6406 50.3828 25.8281C50.3828 12.0156 39.1906 0.828125 25.3828 0.828125Z"
                        fill="#FF5959"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_669_5536">
                        <rect
                          width="50"
                          height="50"
                          fill="white"
                          transform="translate(0.382812 0.828125)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </td>
                <td style={{ background: "white" }}>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25 0C11.225 0 0 11.225 0 25C0 38.775 11.225 50 25 50C38.775 50 50 38.775 50 25C50 11.225 38.775 0 25 0ZM36.95 19.25L22.775 33.425C22.425 33.775 21.95 33.975 21.45 33.975C20.95 33.975 20.475 33.775 20.125 33.425L13.05 26.35C12.325 25.625 12.325 24.425 13.05 23.7C13.775 22.975 14.975 22.975 15.7 23.7L21.45 29.45L34.3 16.6C35.025 15.875 36.225 15.875 36.95 16.6C37.675 17.325 37.675 18.5 36.95 19.25Z"
                      fill="#61A35C"
                    />
                  </svg>
                </td>
                <td style={{ background: "white" }}>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25 0C11.225 0 0 11.225 0 25C0 38.775 11.225 50 25 50C38.775 50 50 38.775 50 25C50 11.225 38.775 0 25 0ZM36.95 19.25L22.775 33.425C22.425 33.775 21.95 33.975 21.45 33.975C20.95 33.975 20.475 33.775 20.125 33.425L13.05 26.35C12.325 25.625 12.325 24.425 13.05 23.7C13.775 22.975 14.975 22.975 15.7 23.7L21.45 29.45L34.3 16.6C35.025 15.875 36.225 15.875 36.95 16.6C37.675 17.325 37.675 18.5 36.95 19.25Z"
                      fill="#61A35C"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" style={{ background: "white" }}>
                  Graph Download Options
                </th>
                <td style={{ background: "white" }}>JPEG</td>
                <td style={{ background: "white" }}>JPEG, PNG, PDF</td>
                <td style={{ background: "white" }}>JPEG, PNG, PDF, EPS</td>
              </tr>
              <tr>
                <th scope="row" style={{ background: "white" }}>
                  Project Report in PDF
                </th>
                <td style={{ background: "white" }}>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_669_5536)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M34.2218 32.4531C34.8328 33.0625 34.8328 34.0626 34.2218 34.6719C33.6125 35.2813 32.6219 35.2813 32.0109 34.6719L25.3922 28.0469L18.7265 34.7187C18.1109 35.328 17.1141 35.328 16.4985 34.7187C15.8844 34.0937 15.8844 33.0938 16.4985 32.4844L23.1641 25.8125L16.5453 19.2031C15.9344 18.5938 15.9344 17.5937 16.5453 16.9843C17.1531 16.3749 18.1437 16.3749 18.7547 16.9843L25.3735 23.6093L32.0891 16.8907C32.7047 16.2813 33.7 16.2813 34.3156 16.8907C34.9297 17.5157 34.9297 18.4999 34.3156 19.1249L27.6015 25.8438L34.2218 32.4531ZM25.3828 0.828125C11.575 0.828125 0.382812 12.0156 0.382812 25.8281C0.382812 39.6406 11.575 50.8281 25.3828 50.8281C39.1906 50.8281 50.3828 39.6406 50.3828 25.8281C50.3828 12.0156 39.1906 0.828125 25.3828 0.828125Z"
                        fill="#FF5959"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_669_5536">
                        <rect
                          width="50"
                          height="50"
                          fill="white"
                          transform="translate(0.382812 0.828125)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </td>
                <td style={{ background: "white" }}>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25 0C11.225 0 0 11.225 0 25C0 38.775 11.225 50 25 50C38.775 50 50 38.775 50 25C50 11.225 38.775 0 25 0ZM36.95 19.25L22.775 33.425C22.425 33.775 21.95 33.975 21.45 33.975C20.95 33.975 20.475 33.775 20.125 33.425L13.05 26.35C12.325 25.625 12.325 24.425 13.05 23.7C13.775 22.975 14.975 22.975 15.7 23.7L21.45 29.45L34.3 16.6C35.025 15.875 36.225 15.875 36.95 16.6C37.675 17.325 37.675 18.5 36.95 19.25Z"
                      fill="#61A35C"
                    />
                  </svg>
                </td>
                <td style={{ background: "white" }}>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25 0C11.225 0 0 11.225 0 25C0 38.775 11.225 50 25 50C38.775 50 50 38.775 50 25C50 11.225 38.775 0 25 0ZM36.95 19.25L22.775 33.425C22.425 33.775 21.95 33.975 21.45 33.975C20.95 33.975 20.475 33.775 20.125 33.425L13.05 26.35C12.325 25.625 12.325 24.425 13.05 23.7C13.775 22.975 14.975 22.975 15.7 23.7L21.45 29.45L34.3 16.6C35.025 15.875 36.225 15.875 36.95 16.6C37.675 17.325 37.675 18.5 36.95 19.25Z"
                      fill="#61A35C"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" style={{ background: "white" }}>
                  Additional Features
                </th>
                <td style={{ background: "white" }}>Step Size</td>
                <td style={{ background: "white" }}>
                  Step Size, Smooth Line, Grey Scale
                </td>
                <td style={{ background: "white" }}>
                  Step Size, Smooth Line, Grey Scale
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Pricing;
