export const csvDownloadFile = async (projectId, projectname, axios) => {
  try {
    const response = await axios.post(
      "analysis/projectDownload",
      { projectID: projectId },
      { responseType: "blob", withCredentials: true }
    );
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    // setFileUrl(url);

    // Automatically initiate the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${projectname}.zip`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error("Error fetching zip file:", error);
  }
};
