// talalkhan FullScreenLoader
import React from 'react';
import { Circles } from 'react-loader-spinner';
import '../assets/css/FullScreenLoader.css'; // Create this CSS file for styling

const FullScreenLoader = () => {
  return (
    <div className="fullscreen-loader">
      <Circles
        height="100"
        width="100"
        color="#15589c"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default FullScreenLoader;
