export const projectCreate = async (plan, projectFile, projectName, setProjectFile, setProjectName, getProjectCount, getProjectDetails, getProjectGraphCount, navigate, toast) => {
  if (plan === 0) {
    toast("Subscribe to plan please", {
      type: "info",
      autoClose: 6000,
    });
    navigate("/profile");
    return;
  }
  const formData = new FormData();
  formData.append("file", projectFile);
  formData.append("projectname", projectName);
  const res = await fetch(
    "analysis/projectCreate",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
      credentials: "include",
    }
  );

  const data = await res.json();
  console.log(data);
  if (data.message) {
    toast(data.message, {
      type: "success",
      autoClose: 6000,
    });
  } else if (data.error) {
    toast(data.error, {
      type: "error",
      autoClose: 6000,
    });
  }
  setProjectFile(null);
  setProjectName("");
  getProjectDetails();
  getProjectCount();
  getProjectGraphCount();
  // setMyProjectsCount(data.projectsCount);
  // if (data.message) {
  //   navigate("/");
  // }
};