// export const getProjectGraphCount = async (setMyProjectsGraphCount, setAllProjectsGraphCount, setSharedProjectsGraphCount) => {
//   console.log("graohs counts");
//   const res = await fetch(
//     "http://64.227.180.23:8800/analysis/projectGraphCount",
//     {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//       credentials: "include",
//     }
//   );

//   const data = await res.json();
//   console.log(data);
//   setMyProjectsGraphCount(data.yourProjectsCountByMonth);
//   setAllProjectsGraphCount(data.projectsCountByMonth);
//   setSharedProjectsGraphCount(data.sharedProjectsCountByMonth);
//   //setMyProjectsCount(data.projectsCount);
//   // if (data.message) {
//   //   navigate("/");
//   // }
// };


// Talal khan
export const getProjectGraphCount = async (setMyProjectsGraphCount, setAllProjectsGraphCount, setSharedProjectsGraphCount) => {
  console.log("graphs counts");
  try {
    const res = await fetch("analysis/projectGraphCount", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    console.log(data);
    setMyProjectsGraphCount(data.yourProjectsCountByMonth);
    setAllProjectsGraphCount(data.projectsCountByMonth);
    setSharedProjectsGraphCount(data.sharedProjectsCountByMonth);
  } catch (error) {
    console.error("Failed to fetch project graph count:", error);
  }
};
// Talal Khan