export const userDelete = async (toast) => {
    const res = await fetch("account/deleteUser", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    const data = await res.json();
    if (data.error || !data) {
        toast(data.error, {
            type: "error",
            autoClose: 6000,
          });
    } else {
        toast(data.message, {
            type: "success",
            autoClose: 6000,
          });
    }
  };
  