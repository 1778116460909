import React, { useState, useEffect } from "react";
import "../../assets/css/Table.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import axios from "axios";

export function UserTable({
  jsonData,
  xLabel,
  yLabel,
  onResponse,
  projectId,
  fileId,
  accessType,
  fileName,
}) {
  useEffect(() => {
    setTableData(jsonData);
  }, [jsonData]);
  const [tableData, setTableData] = useState(jsonData); // State to manage table data
  const [selectedCell, setSelectedCell] = useState({ row: -1, column: -1 });

  const handleCellChange = async (newValue, rowIndex, columnIndex) => {
    console.log(newValue, columnIndex, rowIndex);
    if (newValue.length === 0) {
      return;
    }
    const columnKey = Object.keys(jsonData[0])[columnIndex];

    const updatedData = tableData.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          [Object.keys(row)[columnIndex]]: newValue,
        };
      }
      return row;
    });
    setTableData(updatedData);
    console.log(rowIndex, columnIndex, projectId, fileId, columnKey);
    const data = await fetch(
      "analysis/usereditable",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          rowIndex: rowIndex,
          columnIndex: columnIndex,
          value: newValue,
          xLabel: xLabel,
          yLabel: yLabel,
          fileId: fileId,
          projectId: projectId,
          columnKey: columnKey,
        }),
        credentials: "include",
      }
    );

    const response = await data.json();
    console.log(response);
    onResponse(response);
  };

  const handleCellClick = (rowIndex, columnIndex) => {
    setSelectedCell({ row: rowIndex, column: columnIndex });
  };

  const handleKeyPress = (e, rowIndex, columnIndex) => {
    if (e.key === "Enter") {
      handleCellChange(e.target.value, rowIndex, columnIndex);
    }
  };

  const fileDownload = async () => {
    try {
      const response = await axios.post(
        "analysis/fileDownload",
        { projectId: projectId, fileId: fileId },
        { responseType: "blob", withCredentials: true }
      );
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      // setFileUrl(url);

      // Automatically initiate the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.zip`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error fetching csv file:", error);
    }
  };

  return (
    <div className="home-data-table">
      <div className="d-flex flex-row align-items-center justify-content-center">
        <h4
          className="my-3 text-center"
          style={{ color: "var(--primary-color)" }}
        >
          Extracted Data
        </h4>
        <Link
          className="mx-2"
          onClick={() => {
            fileDownload();
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5 30C5 18.2149 5 12.3223 8.66117 8.66117C12.3223 5 18.2149 5 30 5C41.785 5 47.6777 5 51.3387 8.66117C55 12.3223 55 18.2149 55 30C55 41.785 55 47.6777 51.3387 51.3387C47.6777 55 41.785 55 30 55C18.2149 55 12.3223 55 8.66117 51.3387C5 47.6777 5 41.785 5 30ZM30 15.625C31.0355 15.625 31.875 16.4645 31.875 17.5V30.4732L36.1742 26.1742C36.9065 25.442 38.0935 25.442 38.8258 26.1742C39.558 26.9065 39.558 28.0935 38.8258 28.8258L31.3258 36.3258C30.9743 36.6775 30.4972 36.875 30 36.875C29.5028 36.875 29.0257 36.6775 28.6742 36.3258L21.1742 28.8258C20.442 28.0935 20.442 26.9065 21.1742 26.1742C21.9064 25.442 23.0936 25.442 23.8258 26.1742L28.125 30.4732V17.5C28.125 16.4645 28.9645 15.625 30 15.625ZM20 40.625C18.9645 40.625 18.125 41.4645 18.125 42.5C18.125 43.5355 18.9645 44.375 20 44.375H40C41.0355 44.375 41.875 43.5355 41.875 42.5C41.875 41.4645 41.0355 40.625 40 40.625H20Z"
              fill="#15589c"
            />
          </svg>
        </Link>
      </div>
      <table
        className={`w-100 ${
          accessType === "read" ? "disabled-component-table" : ""
        }`}
      >
        <thead>
          <tr>
            {Object.keys(jsonData[0]).map((key) => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.values(row).map((value, columnIndex) => (
                <td
                  key={columnIndex}
                  style={{
                    border:
                      selectedCell.row === rowIndex &&
                      selectedCell.column === columnIndex
                        ? "2px solid #15589c"
                        : "",
                    backgroundColor:
                      selectedCell.row === rowIndex &&
                      selectedCell.column === columnIndex
                        ? "lightyellow"
                        : "#a1bcd7",
                  }}
                  onClick={() => handleCellClick(rowIndex, columnIndex)}
                >
                  <input
                    type="text"
                    value={value}
                    onChange={(e) =>
                      setTableData((prevTableData) => {
                        const newData = [...prevTableData];
                        newData[rowIndex][Object.keys(row)[columnIndex]] =
                          e.target.value;
                        return newData;
                      })
                    }
                    onKeyPress={(e) => handleKeyPress(e, rowIndex, columnIndex)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UserTable;
