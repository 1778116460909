import { React, useState, useEffect } from "react";
import Header from "../Components/Header";
import { Link, useNavigate } from "react-router-dom";
import "../assets/css/Signup.css";
import SignupLoader from "../Components/Loaders/SignupLoader";
import HomeSectionBg from "../Components/Loaders/HomeSectionBg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderLogo from "../Components/HeaderLogo";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

export function Signup() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const renderBg = () => {
    const elements = [];

    for (let i = 0; i < 1; i++) {
      elements.push(
        <div key={i}>
          <HomeSectionBg />
        </div>
      );
    }

    return elements;
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (!validateEmail(newEmail)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);

    if (confirmPassword !== password) {
      setConfirmPasswordError("Password not matched");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (newPassword.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
    } else {
      setPasswordError("");
    }
  };

  const handleName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const registerUser = async (e) => {
    e.preventDefault();
    toast("500 Website is currently down", {
      type: "error",
      autoClose: 6000,
    });
    setLoading(true);

    const res = await fetch("account/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        name: name,
      }),
    });

    const data = await res.json();

    if (data.error || !data) {
      toast(data.error, {
        type: "error",
        autoClose: 6000,
      });
      // window.location.reload();
    } else {
      toast("Successfully Registered", {
        type: "success",
        autoClose: 6000,
      });
      navigate("/login");
    }
    setLoading(false);
  };

  useEffect(() => {
    // if (!gitHit) {
    console.log("Why");
    // setGitHit(true);
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");

    if (code) {
      fetch("account/github/callback", {
        method: "POST",
        body: JSON.stringify({ code }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            navigate("/dashboard");
          } else if (data.error) {
            toast(data.error, {
              type: "error",
              autoClose: 6000,
            });
          } else {
            toast("Unexpected Error", {
              type: "error",
              autoClose: 6000,
            });
          }
        })
        .catch((error) => {
          toast(String(error), {
            type: "error",
            autoClose: 6000,
          });
        });
    }
    // }
  }, [navigate]);

  const handleGithubLogin = () => {
    window.location.href =
      "https://github.com/login/oauth/authorize?scope=user&client_id=Ov23lilF0qPJ7JAM1eAb&redirect_uri=http://192.168.1.76:3000/login";
  };

  const handleSuccess = (response) => {
    const decoded = jwtDecode(response.credential);
    console.log("Decoded JWT:", decoded);

    // Extract user information
    const user = {
      name: decoded.name,
      email: decoded.email,
      picture: decoded.picture,
    };

    fetch("account/googleLogin", {
      method: "POST",
      body: JSON.stringify({
        name: user.name,
        email: user.email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          navigate("/dashboard");
        } else if (data.error) {
          toast(data.error, {
            type: "error",
            autoClose: 6000,
          });
        } else {
          toast("Unexpected Error", {
            type: "error",
            autoClose: 6000,
          });
        }
      })
      .catch((error) => {
        toast(String(error), {
          type: "error",
          autoClose: 6000,
        });
      });
  };

  const handleFailure = (response) => {
    console.log("Login failed:", response);
    // Handle login failure
  };

  return (
    <div className="signup_page">
      <Header />
      {/* <div className="homebg d-flex flex-row justify-content-around align-items-center flex-wrap">
                {renderBg()}
            </div> */}
      <div className="login-page d-flex flex-column align-items-center mb-3">
        <HeaderLogo />
        <h5 className="mt-3 mb-2">Register for PlotAnt</h5>
        <form
          action=""
          method="post"
          className="login-form d-flex flex-column"
          style={{ width: "400px" }}
        >
          <div className="d-flex flex-column w-100 align-items-center">
            {/* <div className="d-flex flex-row align-items-center justify-content-center py-1 px-2 login-google mt-3 mb-2 "> */}
            {/* <img
                src={require("../assets/imgs/google.png")}
                alt=""
                className=""
              />
              
              <p className="">Login with Google</p> */}
            <GoogleOAuthProvider clientId="1002472500377-vi31jo7u7go6d0rugck0touirlnif15r.apps.googleusercontent.com">
              <div style={{ maxWidth: "max-content" }} className="my-2">
                <GoogleLogin
                  onSuccess={handleSuccess}
                  onFailure={handleFailure}
                  cookiePolicy={"single_host_origin"}
                />
              </div>
            </GoogleOAuthProvider>
            {/* </div> */}
            <div>
              <a
                onClick={handleGithubLogin}
                className="d-flex flex-row align-items-center github-link justify-content-center py-2 px-2 mb-3 w-100"
                style={{ color: "black" }}
              >
                <img
                  src={require("../assets/imgs/github.png")}
                  alt=""
                  className="mx-1"
                />
                <p className="mx-1">Login with Github</p>
              </a>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center w-100 my-3">
            <div
              className="flex-grow-1 hr-line border-bottom"
              style={{ border: "1px solid lightgray" }}
            ></div>
            <p className="mx-2 mb-0 text-muted">or</p>
            <div
              className="flex-grow-1 hr-line border-bottom"
              style={{ border: "1px solid lightgray" }}
            ></div>
          </div>
          <label
            htmlFor=""
            className="mt-3"
            style={{ color: "gray", fontWeight: "bold" }}
          >
            Email
          </label>
          <input
            type="email"
            name=""
            id=""
            value={email}
            onChange={handleEmailChange}
            className="login-input py-1"
          />
          {emailError && (
            <p style={{ color: "red", fontSize: "12px" }} className="">
              {emailError}
            </p>
          )}
          <label
            htmlFor=""
            className="mt-3"
            style={{ color: "gray", fontWeight: "bold" }}
          >
            Name
          </label>
          <input
            type="text"
            name="name"
            id=""
            value={name}
            onChange={handleName}
            className="login-input py-1"
          />
          <label
            htmlFor=""
            className="mt-3"
            style={{ color: "gray", fontWeight: "bold" }}
          >
            Password
          </label>
          <input
            type="password"
            name=""
            id=""
            value={password}
            onChange={handlePasswordChange}
            className="login-input py-1"
          />
          {passwordError && (
            <p style={{ color: "red", fontSize: "12px" }} className="">
              {passwordError}
            </p>
          )}
          <label
            htmlFor=""
            className="mt-3"
            style={{ color: "gray", fontWeight: "bold" }}
          >
            Confirm Password
          </label>
          <input
            type="password"
            name="confirmpassword"
            id=""
            value={confirmpassword}
            onChange={handleConfirmPasswordChange}
            className="login-input py-1"
          />
          {confirmPasswordError && (
            <p style={{ color: "red", fontSize: "12px" }} className="">
              {confirmPasswordError}
            </p>
          )}

          {loading ? (
            <button
              type="button"
              onClick={registerUser}
              className="btn login-button text-white mt-4"
              disabled
            >
              Registering....
            </button>
          ) : (
            <button
              type="submit"
              onClick={registerUser}
              className="btn login-button text-white mt-4"
            >
              Register using your email
            </button>
          )}

          <p style={{ fontSize: "0.9rem" }} className="mt-3 text-center">
            Already have an account?{" "}
            <Link to="/login" style={{ color: "var(--primary-color)" }}>
              Sign in
            </Link>
          </p>
          <p
            className="text-center mt-3"
            style={{ color: "gray", fontSize: "0.75rem" }}
          >
            By registering, you agree to our terms of service and privacy
            notice.
          </p>
          <p
            className="text-center mt-3"
            style={{ color: "gray", fontSize: "0.75rem" }}
          >
            The site is protected by reCAPTCHA and the Google Privacy Policy and
            Terms of Service apply.
          </p>
        </form>
      </div>
    </div>
  );
}

export default Signup;
